<template>
    <div class="content" v-if="isFinished">
        <div v-if="xianshi1">
            <div class="content_top">
                <div></div>
                <div>
                    <el-button type="primary" @click="printPreview" icon="el-icon-reading" style="background-color: #62B5FB; border-color: #62B5FB;border-radius: 24px;">
                        打印预览
                    </el-button>
                    <el-button type="primary" v-print="printObj" icon="el-icon-printer" style="background-color: #62B5FB; border-color: #62B5FB;border-radius: 24px;">
                        打印
                    </el-button>
                    <el-button type="warning" @click="dialogPrint = true" icon="el-icon-s-tools" style="background-color: #8197FE; border-color: #8197FE;border-radius: 24px;">
                        打印设置
                    </el-button>
                    <el-button class="pink" style="color: #333333!important;border-radius: 24px;" plain @click="$router.go(-1)" icon="el-icon-arrow-left">
                        返回主页面
                    </el-button>
                </div>
            </div>
            <div class="content_body" ref="contentBody">
                <div class="print-html" ref="print" id="print">
                    <div class="title">
                        <img v-if="infoData.hospital_info && infoData.hospital_info.logo" :src="infoData.hospital_info.logo">
                        <div>
                            <p>{{ infoData.hospital_info && infoData.hospital_info.hospital_name }}</p>
                            <span>{{ infoData.hospital_info && infoData.hospital_info.second_name }}</span>
                        </div>
                    </div>
                    <div class="table-name">
                        <p style="font-size: 28px;">{{  infoData.title  }}
                            <span v-if="$store.state.user.hukou == 1">（农村版）</span><span v-if="$store.state.user.hukou == 2">（城市版）</span></p>
                        <span class="edit-btn print-none  print-none_" @click="xiu" style="display: block"><i
                            class="el-icon-edit"></i>修改报告</span>
                    </div>
                    <div class="print-body">
                        <div class="first ">
                            <div class="first-group">
                                <div class="row">
                                    <span>患者姓名</span>
                                    <p>{{ infoData.patient_info && infoData.patient_info.name }}</p>
                                </div>
                                <div class="row">
                                    <span>病历号</span>
                                    <p>{{ infoData.patient_info && infoData.patient_info.medical_num }}</p>
                                </div>
                                <div class="row">
                                    <span>入院时间</span>
                                    <p>{{ infoData.patient_info && infoData.patient_info.in_time }}</p>
                                </div>
                                <div class="row">
                                    <span>父亲姓名</span>
                                    <p>{{ infoData.patient_info && infoData.patient_info.father_name }}</p>
                                </div>
                                <div class="row">
                                    <span>母亲姓名</span>
                                    <p>{{ infoData.patient_info && infoData.patient_info.mother_name }}</p>
                                </div>
                                <div class="row">
                                    <span>检测人员</span>
                                    <p>{{ infoData.patient_info && infoData.patient_info.check_name }}</p>
                                </div>
                            </div>
                            <div class="first-group">
                                <div class="row">
                                    <span>患者性别</span>
                                    <p>{{ infoData.patient_info && infoData.patient_info.gender == 1 ? '女' : '男' }}</p>
                                </div>
                                <div class="row">
                                    <span>医保号</span>
                                    <p>{{ infoData.patient_info && infoData.patient_info.medical_insurance_num }}</p>
                                </div>
                                <div class="row">
                                    <span>测评时间</span>
                                    <p>{{ infoData.patient_info && infoData.patient_info.exam_start_time }}</p>
                                </div>
                                <div class="row">
                                    <span>父亲文化</span>
                                    <p>{{ infoData.patient_info && infoData.patient_info.father_education }}</p>
                                </div>
                                <div class="row">
                                    <span>母亲文化</span>
                                    <p>{{ infoData.patient_info && infoData.patient_info.mother_education }}</p>
                                </div>
                            </div>
                            <div class="first-group">
                                <div class="row">
                                    <span>患者年龄</span>
                                    <p>{{ infoData.patient_info && infoData.patient_info.age }}</p>
                                </div>
                                <div class="row">
                                    <span>患者来源</span>
                                    <p>{{ infoData.patient_info && infoData.patient_info.source }}</p>
                                </div>
                                <div class="row">
                                    <span>完成时间</span>
                                    <p>{{ infoData.patient_info && infoData.patient_info.complete_time }}</p>
                                </div>
                                <div class="row">
                                    <span>父亲职业</span>
                                    <p>{{ infoData.patient_info && infoData.patient_info.father_occupation }}</p>
                                </div>
                                <div class="row">
                                    <span>母亲职业</span>
                                    <p>{{ infoData.patient_info && infoData.patient_info.mother_occupation }}</p>
                                </div>
                            </div>
                        </div>

                        <!--111-->
                        <template >
                            <div class="MMPICT print-row" :class="printData[0].value ? '' : 'print-none'">
                                <div class="table-name">
                                    <p>{{ infoData.detail.wisc_info.name }}</p>
                                </div>
                                <div class="MMPICT-ii flex">
                                    <div class="table-two ">
                                        <div class="charts-wrap">
                                            <div id="myChartA" :style="{width: '550px', height: '400px'}"></div>
                                        </div>
<!--                                        <el-table :data="comDanger"-->
<!--                                                  :header-cell-style="{fontSize:'16px',color:'#333'}"-->
<!--                                                  border>-->
<!--                                            <el-table-column-->
<!--                                                prop="title"-->
<!--                                                align="center"-->
<!--                                                width="200px"-->
<!--                                                label="">-->
<!--                                                <template slot-scope="scope">-->
<!--                                                    <div v-if="scope.row.last" style="color:#fff">-->
<!--                                                        {{ scope.row.title }}-->
<!--                                                    </div>-->
<!--                                                    <div v-else :title="scope.row.desc">-->
<!--                                                        {{ scope.row.title }}-->
<!--                                                    </div>-->
<!--                                                </template>-->
<!--                                            </el-table-column>-->
<!--                                            <el-table-column-->
<!--                                                prop="score"-->
<!--                                                align="center"-->
<!--                                                label="语言测验">-->

<!--                                                <template slot-scope="scope">-->
<!--                                                    <div class="table-three">-->
<!--                                                        <t-score :table="3" :id="'table3'+scope.$index" :first="true"-->
<!--                                                                 class="tscore" v-if="scope.row.first">-->
<!--                                                        </t-score>-->
<!--                                                        <t-score :table="3" :id="'table3'+scope.$index" :last="true"-->
<!--                                                                 :score="scope.row.score" class="tscore"-->
<!--                                                                 v-else-if="scope.row.last">-->
<!--                                                        </t-score>-->
<!--                                                        <t-score :table="3" :tick="scope.$index === 6"-->
<!--                                                                 :id="'table3'+scope.$index" :score="scope.row.score"-->
<!--                                                                 class="tscore" v-else>-->
<!--                                                            {{ scope.row.score }}-->
<!--                                                        </t-score>-->

<!--                                                    </div>-->
<!--                                                </template>-->
<!--                                            </el-table-column>-->
<!--                                        </el-table>-->
                                    </div>
                                    <div class="table-two">
                                        <div class="charts-wrap">
                                            <div id="myChartB" :style="{width: '550px', height: '400px'}"></div>
                                        </div>
<!--                                        <el-table :data="comDanger01"-->
<!--                                                  :header-cell-style="{fontSize:'16px',color:'#333'}"-->
<!--                                                  border-->
<!--                                        >-->
<!--                                            <el-table-column-->
<!--                                                prop="title"-->
<!--                                                align="center"-->
<!--                                                width="200px"-->
<!--                                                label="">-->
<!--                                                <template slot-scope="scope">-->
<!--                                                    <div v-if="scope.row.last" style="color:#fff">-->
<!--                                                        {{ scope.row.title }}-->
<!--                                                    </div>-->
<!--                                                    <div v-else :title="scope.row.desc">-->
<!--                                                        {{ scope.row.title }}-->
<!--                                                    </div>-->
<!--                                                </template>-->
<!--                                            </el-table-column>-->
<!--                                            <el-table-column-->
<!--                                                prop="score"-->
<!--                                                align="center"-->
<!--                                                label="操作测验">-->

<!--                                                <template slot-scope="scope">-->
<!--                                                    <div class="table-three">-->
<!--                                                        <t-score :table="3" :id="'table3'+scope.$index" :first="true"-->
<!--                                                                 class="tscore" v-if="scope.row.first">-->
<!--                                                        </t-score>-->
<!--                                                        <t-score :table="3" :id="'table3'+scope.$index" :last="true"-->
<!--                                                                 :score="scope.row.score" class="tscore"-->
<!--                                                                 v-else-if="scope.row.last">-->
<!--                                                        </t-score>-->
<!--                                                        <t-score :table="3" :tick="scope.$index === 5"-->
<!--                                                                 :id="'table3'+scope.$index" :score="scope.row.score"-->
<!--                                                                 class="tscore" v-else>-->
<!--                                                            {{ scope.row.score }}-->
<!--                                                        </t-score>-->

<!--                                                    </div>-->
<!--                                                </template>-->
<!--                                            </el-table-column>-->
<!--                                        </el-table>-->
                                    </div>
                                </div>
                            </div>
                            <div class="MMPICT print-row" :class="printData[2].value ? '' : 'print-none'">
                                <div class="table-name">
                                    <p>{{ infoData.detail.jcjg_info.name }}</p>
                                </div>
                                <div class="MMPICT-ii">
                                    <div class="table-one">
                                        <el-table
                                            border
                                            :data="infoData.detail.jcjg_info.child.yuyan"
                                            :header-row-style="{color:'#333333',fontSize:'16px'}"
                                            :header-cell-class-name="comCell3"
                                            :cell-class-name="comScoreClass"
                                            :header-row-class-name="'table-two-header-row'"
                                            style="width:49%;margin-right: 1%">
                                            <el-table-column
                                                label="言语测验"
                                                prop="title"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                label="粗分"
                                                prop="cf"
                                                width="100"
                                            />
                                            <el-table-column
                                                label="量表分"
                                                prop="bz"
                                                width="100"
                                            />

                                        </el-table>
                                        <el-table
                                            border
                                            :data="infoData.detail.jcjg_info.child.caozuo"
                                            :header-row-style="{color:'#333333',fontSize:'16px'}"
                                            :header-cell-class-name="comCell3"
                                            :cell-class-name="comScoreClass"
                                            :header-row-class-name="'table-two-header-row'"
                                            style="width:49%;">
                                            <el-table-column
                                                label="操作测验"
                                                prop="title"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                label="粗分"
                                                prop="cf"
                                                width="100"
                                            />
                                            <el-table-column
                                                label="量表分"
                                                prop="bz"
                                                width="100"
                                            />

                                        </el-table>
                                    </div>
                                </div>
                            </div>
                            <div class="MMPICT print-row" :class="printData[2].value ? '' : 'print-none'">
                                <div class="table-name">
                                    <p style="width: 50%;text-align: center;">{{ infoData.detail.yycz_info.name }}</p>
                                    <p style="width: 50%;text-align: center;">{{ infoData.detail.yzfxll_info.name }}</p>
                                </div>
                                <div class="MMPICT-ii">
                                    <div class="table-one">
                                        <el-table
                                            border
                                            :data="infoData.detail.yycz_info.child"
                                            :header-row-style="{color:'#333333',fontSize:'16px'}"
                                            :header-cell-class-name="comCell3"
                                            :cell-class-name="comScoreClass"
                                            :header-row-class-name="'table-two-header-row'"
                                            style="width:49%;margin-right: 1%">
                                            <el-table-column
                                                label="量表"
                                                prop="title"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                label="量表分"
                                                prop="lb"
                                                width="100"
                                            />
                                            <el-table-column
                                                label="智商"
                                                prop="zs"
                                                width="100"
                                            />

                                        </el-table>
                                        <el-table
                                            border
                                            :data="infoData.detail.yzfxll_info.child"
                                            :header-row-style="{color:'#333333',fontSize:'16px'}"
                                            :header-cell-class-name="comCell3"
                                            :cell-class-name="comScoreClass"
                                            :header-row-class-name="'table-two-header-row'"
                                            style="width:49%">
                                            <el-table-column
                                                label="理论名称"
                                                prop="title"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                label="成分"
                                                prop="info"
                                                width="180"
                                            />
                                            <el-table-column
                                                label="因子分"
                                                prop="yinzi"
                                                width="100"
                                            />
                                            <el-table-column
                                                label="IQ"
                                                prop="iq"
                                                width="100"
                                            />

                                        </el-table>
                                    </div>
                                </div>
                            </div>
                            <div class="MMPICT print-row" :class="printData[2].value ? '' : 'print-none'">
                                <div class="table-name">
                                    <p style="width: 50%;text-align: center;">{{ infoData.detail.yzfx_info.name }}</p>
                                    <p style="width: 50%;text-align: center;">{{ infoData.detail.vpcy_info.name }}</p>
                                </div>
                                <div class="MMPICT-ii">
                                    <div class="table-one">
                                        <el-table
                                            border
                                            :data="infoData.detail.yzfx_info.child"
                                            :header-row-style="{color:'#333333',fontSize:'16px'}"
                                            :header-cell-class-name="comCell3"
                                            :cell-class-name="comScoreClass"
                                            :header-row-class-name="'table-two-header-row'"
                                            style="width:49%;margin-right: 1%">
                                            <el-table-column
                                                label="理论名称"
                                                prop="title"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                label="言语理解因子"
                                                prop="yuyan"
                                                width="140"
                                            />
                                            <el-table-column
                                                label="知觉组织因子"
                                                prop="zhijue"
                                                width="140"
                                            />

                                        </el-table>
                                        <el-table
                                            border
                                            :data="infoData.detail.vpcy_info.child"
                                            :header-row-style="{color:'#333333',fontSize:'16px'}"
                                            :header-cell-class-name="comCell3"
                                            :cell-class-name="comScoreClass"
                                            :header-row-class-name="'table-two-header-row'"
                                            style="width:49%">
                                            <el-table-column
                                                label="言语智商"
                                                prop="yuyan"
                                            >
                                            </el-table-column>
                                            <el-table-column
                                                label="操作智商"
                                                prop="caozuo"
                                            />
                                            <el-table-column
                                                label="V-P差值"
                                                prop="vp"
                                            />

                                        </el-table>
                                    </div>
                                </div>
                            </div>
                            <div class="MMPICT print-row" :class="printData[2].value ? '' : 'print-none'">
                                <div class="table-name">
                                    <p style="width: 50%;text-align: center;">{{ infoData.detail.gfcyqlb_info.name }}</p>
                                    <p style="width: 50%;text-align: center;">{{ infoData.detail.gfcyflb_info.name }}</p>
                                </div>
                                <div class="MMPICT-ii">
                                    <div class="table-one">
                                        <div style="margin-right: 1%;width: 49%;">
                                            <div class="table-name-box" style="">
                                                <div style="border-right: 1px solid #EBEEF5;">言语</div>
                                                <div style="">操作</div>
                                            </div>
                                            <div class="MMPICT-ii">
                                                <div class="table-one">
                                                    <el-table
                                                        border
                                                        :data="infoData.detail.gfcyqlb_info.child.yuyan"
                                                        :header-row-style="{color:'#333333',fontSize:'16px'}"
                                                        :header-cell-class-name="comCell3"
                                                        :cell-class-name="comScoreClass"
                                                        :header-row-class-name="'table-two-header-row'"
                                                        style="width:49%;">
                                                        <el-table-column
                                                            label="分测验"
                                                            prop="title"
                                                        >
                                                        </el-table-column>
                                                        <el-table-column
                                                            label="量表分"
                                                            prop="score"
                                                        />

                                                    </el-table>
                                                    <el-table
                                                        border
                                                        :data="infoData.detail.gfcyqlb_info.child.caozuo"
                                                        :header-row-style="{color:'#333333',fontSize:'16px'}"
                                                        :header-cell-class-name="comCell3"
                                                        :cell-class-name="comScoreClass"
                                                        :header-row-class-name="'table-two-header-row'"
                                                        style="width:49%">
                                                        <el-table-column
                                                            label="分测验"
                                                            prop="title"
                                                        >
                                                        </el-table-column>
                                                        <el-table-column
                                                            label="量表分"
                                                            prop="score"
                                                        />

                                                    </el-table>
                                                </div>
                                            </div>
                                            <div class="table-name-box-bottom" style="border: 1px solid #EBEEF5">
                                                <div style="width: 100%;">平均量表分{{ infoData.detail.gfcyqlb_info.child.all_pj }}</div>
                                            </div>
                                        </div>
                                        <div style="width: 49%;">
                                            <div class="table-name-box" style="">
                                                <div style="border-right: 1px solid #EBEEF5;">言语</div>
                                                <div style="">操作</div>
                                            </div>
                                            <div class="MMPICT-ii">
                                                <div class="table-one">
                                                    <el-table
                                                        border
                                                        :data="infoData.detail.gfcyflb_info.child.yuyan"
                                                        :header-row-style="{color:'#333333',fontSize:'16px'}"
                                                        :header-cell-class-name="comCell3"
                                                        :cell-class-name="comScoreClass"
                                                        :header-row-class-name="'table-two-header-row'"
                                                        style="width:49%;">
                                                        <el-table-column
                                                            label="分测验"
                                                            prop="title"
                                                        >
                                                        </el-table-column>
                                                        <el-table-column
                                                            label="量表分"
                                                            prop="score"
                                                        />

                                                    </el-table>
                                                    <el-table
                                                        border
                                                        :data="infoData.detail.gfcyflb_info.child.caozuo"
                                                        :header-row-style="{color:'#333333',fontSize:'16px'}"
                                                        :header-cell-class-name="comCell3"
                                                        :cell-class-name="comScoreClass"
                                                        :header-row-class-name="'table-two-header-row'"
                                                        style="width:49%">
                                                        <el-table-column
                                                            label="分测验"
                                                            prop="title"
                                                        >
                                                        </el-table-column>
                                                        <el-table-column
                                                            label="量表分"
                                                            prop="score"
                                                        />

                                                    </el-table>
                                                </div>
                                            </div>
                                            <div class="table-name-box-bottom" style="">
                                                <div style="width: 50%;border-right: 1px solid #EBEEF5;border-bottom: 1px solid #EBEEF5;border-left: 1px solid #EBEEF5;">平均{{ infoData.detail.gfcyflb_info.child.yuyan_pj }}</div>
                                                <div style="width: 50%;border-right: 1px solid #EBEEF5;border-bottom: 1px solid #EBEEF5;border-top: 1px solid #EBEEF5;">平均{{ infoData.detail.gfcyflb_info.child.caozuo_pj }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="MMPICT print-row" :class="printData[2].value ? '' : 'print-none'"
                                 v-if="infoData.detail.average_compare.child.title && infoData.detail.average_compare.child.title.length>0">
                                <div class="table-name">
                                    <p>{{ infoData.detail.average_compare.name }}</p>
                                </div>
                                <div class="MMPICT-ii">
                                    <div class="table-one">
                                        <div>
                                            <div class="titleTop">
                                                <div> </div>
                                                <div v-for="(item,index) in infoData.detail.average_compare.child.title" :key="index">
                                                    {{ item }}
                                                </div>
                                            </div>
                                            <div class="titleTop titleTop1" v-for="(item,index) in infoData.detail.average_compare.child.info" :key="index">
                                                <div v-for="(child,i) in item" :key="i">
                                                    {{ child }}
                                                </div>
                                            </div>
                                            <div class="table-name-box-bottom" style="border: 1px solid #EBEEF5;border-top: none;">
                                                <div style="width: 100%;">注：表内数值为各分测验量表分之差</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="MMPICT print-row" :class="printData[2].value ? '' : 'print-none'">
                                <img src="../assets/images/quxian.jpg" style="width: 100%;">
                            </div>
                            <div class="MMPICT print-row" :class="printData[2].value ? '' : 'print-none'">
                                <div style="text-align: center;line-height: 40px;">{{ infoData.detail.zs.name }}</div>
                                <div class="table-name" style="margin-top: 20px;border: 1px solid #EBEEF5;">
                                    <div style="width: 50%;text-align: left;padding: 20px 80px;border-right: 1px solid #EBEEF5;" class="flex flex-child-center">
                                        {{ infoData.detail.zs.child[0].title }}：<span style="font-size: 18px;color: #62B5FB;font-weight: 600;margin-left: 10px;">{{ infoData.detail.zs.child[0].value }}</span>
                                    </div>
                                    <div style="width: 50%;text-align: left;padding: 20px 80px;">{{ infoData.detail.zs.child[1].title }}：
                                        <span style="color: #333333;margin-left: 10px;font-size: 18px;font-weight: bold;">
                                        {{ infoData.detail.zs.child[1].value }}
                                    </span></div>
                                </div>
                            </div>
                            <div class="print-row" :class="printData[2].value ? '' : 'print-none'">
                                <div class="table-name">
                                    <p>{{ infoData.detail.jgfx_info.name }}</p>
                                </div>
                                <div v-html="infoData.detail.jgfx_info.child" class="report-text-content"
                                     style="font-weight: normal;white-space:pre-wrap;" contenteditable="true"></div>
                            </div>
                            <div class="print-row" :class="printData[3].value ? '' : 'print-none'">
                                <div class="table-name">
                                    <p>行为观察</p>
                                    <span class="edit-btn" v-if="showEdit"
                                          @click="getProposal({title:'行为观察',type:2,field:'suggestion'})"><i
                                        class="el-icon-edit"></i>点击此处修改</span>
                                </div>
                                <!-- <textarea ref="test_five" style="height: 0;" class="report-text-content"  v-html="test_paper_explain"></textarea> -->
                                <!-- <div v-html="infoData.test_paper_explain" class="report-text-content"></div> -->
                                <div v-html="suggestion" class="report-text-content" style="font-weight: normal;white-space:pre-wrap;" contenteditable="true"></div>
                            </div>
                            <div class="print-row" :class="printData[4].value ? '' : 'print-none'">
                                <div class="table-name">
                                    <p>医生建议</p>
                                    <span class="edit-btn" v-if="showEdit"
                                          @click="getProposal({title:'医生建议',type:5,field:'doctor_proposal'})"><i
                                        class="el-icon-edit"></i>点击此处修改</span>
                                </div>
																<!-- <textarea ref="test_one"  class="report-text-content"  v-html="doctor_proposal" style="height: 0"></textarea> -->
                                <!--                        <div v-html="infoData.doctor_proposal" class="report-text-content"></div>-->
																<div v-html="doctor_proposal" class="report-text-content" style="font-weight: normal;white-space:pre-wrap;" contenteditable="true"></div>
                            </div>
                            <div class="print-last-row">
                                <div class="group">
                                    <span>操作员：</span>
                                    <p>{{ infoData.user_img === "" ? infoData.user : '' }}</p>
                                    <p><img style="height: 55px" :src="infoData.user_img"></p>
                                </div>
                                <div class="group">
                                    <span>检测时间：</span>
                                    <p></p>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="tip">***本报告不对任何决定负责！应为被试的报告内容保密！***</div>
                </div>
            </div>
            <el-dialog class="new-dialog" title="打印设置" :visible.sync="dialogPrint">
                <el-form style="max-height: 550px;">
                    <el-form-item label="打印项" label-width="130px">
                        <el-checkbox-group v-model="printForm">
                            <el-checkbox v-for="item in printData" :key="item.id" :label="item.id">
                                {{ item.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="打印大小" label-width="130px">
                        <el-radio-group v-model="printPageSize">
                            <el-radio label="1" style="display: block">A4</el-radio>
                            <div class="space-10"></div>
                            <el-radio label="2" style="display: block">A5</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="修改设置" label-width="130px">
                        <el-radio-group v-model="displayBlock">
                            <el-radio label="2" @change="changeRadio($event)">否</el-radio>
                            <el-radio label="1" @change="changeRadio($event)">是</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogPrint = false" style="margin-right: 10px">取 消</el-button>
                    <el-button type="primary" @click="printSet">确 定</el-button>
                </div>
            </el-dialog>
            <el-dialog class="new-dialog text-dialog" :visible.sync="dialogIpt" width="30%" :close-on-click-modal="false"
                       :show-close="false">
                <div style="width: 100%;">
                    <div class="text-content">
                        <textarea v-model="dialogText" rows="10"></textarea>
                    </div>
                    <div class="button-wrap">
                        <el-button type="primary" @click="textConfirm">确 定</el-button>
                        <el-button @click="dialogIpt = false" style="border: 1px solid #CDCDCD !important;">取 消</el-button>
                    </div>
                </div>
            </el-dialog>
            <el-dialog class="new-dialog text-dialog" :title="modifyTitle.title" :visible.sync="dialogProposal" width="40%"
                       :close-on-click-modal="false"
                       :show-close="false">
                <div style="width: 100%;">
                    <div class="proposal-text">
                        <span>历史模板</span>
                        <el-select style="width: 80%;" v-model="doctorProposal" @change="templateText = $event;"
                                   :popper-append-to-body="false" popper-class="popper-class">
                            <el-option v-for="(item,index) in doctorProposalList" :key="item.id" :label="item.content"
                                       :value="item.content">
                            </el-option>

                        </el-select>
                    </div>
                    <div class="proposal-text">
                        <span>{{ modifyTitle.title }}</span>
                        <textarea v-model="templateText" rows="10"></textarea>
                    </div>
                    <div class="button-wrap">
                        <el-button type="primary" @click="proposalConfirm">确 定</el-button>
                        <el-button @click="dialogProposal = false" style="border: 1px solid #CDCDCD !important;">取 消</el-button>
                    </div>
                </div>
            </el-dialog>
            <el-dialog class="new-dialog text-dialog" title="修改报告" :visible.sync="dialogReport" width="55%"
                       :close-on-click-modal="false"
                       :show-close="false">
                <div style="width: 75%;">
                    <el-form label-width="120px" style=" max-height: unset;display: flex;flex-wrap: wrap;">
                        <el-form-item label="患者姓名">
                            <el-input v-model="xiugai.name"></el-input>
                        </el-form-item>
                        <el-form-item label="患者性别">
                            <el-radio-group v-model="xiugai.gender">
                                <el-radio :label="0" name="0">男</el-radio>
                                <el-radio :label="1" name="1">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="患者年龄">
                            <el-input v-model="xiugai.age"></el-input>
                        </el-form-item>
                        <el-form-item label="入院时间">
                            <el-date-picker type="date" placeholder="选择日期" style="width:100%" value-format="yyyy-MM-dd"
                                            v-model="xiugai.in_time"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="测评时间">
                            <el-date-picker type="date" placeholder="选择日期" style="width:100%" value-format="yyyy-MM-dd"
                                            v-model="xiugai.exam_start_time"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="病历号">
                            <el-input v-model="xiugai.medical_num"></el-input>
                        </el-form-item>
                        <el-form-item label="医保号">
                            <el-input v-model="xiugai.medical_insurance_num"></el-input>
                        </el-form-item>
                        <el-form-item label="患者来源">
                            <el-select v-model="xiugai.source" style="width: 100%;">
                                <el-option v-for="item in wardList" :label="item.name" :key="item.id"
                                           :value="item.name"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="完成时间">
                            <el-input v-model="xiugai.complete_time"></el-input>
                        </el-form-item>
                        <el-form-item label="父亲姓名">
                            <el-input v-model="xiugai.father_name"></el-input>
                        </el-form-item>
                        <el-form-item label="父亲文化">
                            <el-select v-model="xiugai.father_education" style="width: 100%;">
                                <el-option v-for="item in educationInformationArr" :key="item.id" :label="item.name"
                                           :value="item.name"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="父亲职业">
                            <el-select v-model="xiugai.father_occupation" style="width: 100%;">
                                <el-option v-for="item in careerInformationArr" :key="item.id" :label="item.name"
                                           :value="item.name"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="母亲姓名">
                            <el-input v-model="xiugai.mother_name"></el-input>
                        </el-form-item>
                        <el-form-item label="母亲文化">
                            <el-select v-model="xiugai.mother_education" style="width: 100%;">
                                <el-option v-for="item in educationInformationArr" :key="item.id" :label="item.name"
                                           :value="item.name"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="母亲职业">
                            <el-select v-model="xiugai.mother_occupation" style="width: 100%;">
                                <el-option v-for="item in careerInformationArr" :key="item.id" :label="item.name"
                                           :value="item.name"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="检测人员">
                            <el-select v-model="xiugai.check_name" style="width: 100%;">
                                <el-option v-for="item in checkArr" :label="item.name" :key="item.id"
                                           :value="item.name"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <div class="button-wrap">
                        <el-button type="primary" @click="xiuqueding">确 定</el-button>
                        <el-button @click="xiuquxiao" style="border: 1px solid #CDCDCDCDCDCD !important;">取 消</el-button>
                    </div>
                </div>
            </el-dialog>

            <el-dialog :visible.sync="dialogPreview">
                <template slot="title">
                    <div style="display: flex;justify-content: space-between;">
                        <span>打印预览</span>
                        <el-button type="primary" style="margin-right: 30px;" size="mini" v-print="printObj"
                                   icon="el-icon-printer">
                            打印
                        </el-button>
                    </div>
                </template>

                <div class="page-A4">
                    <img :src="previewImg" alt="" v-if="previewImg" class="previewImg">
                </div>
            </el-dialog>
        </div>

        <div v-if="xianshi2">
            <el-dialog class="new-dialog text-dialog" :visible.sync="dialogChuan" modal-append-to-body=true  :before-close="handleClose" width="20%">
                <div>
                    <div><img style="margin:0 auto; display: block; " src="../assets/images/kulian.png"></div>
                    <div style="margin-top: 20px; font-size: 24px; color: #333333; line-height: 31px; ">{{zhi}}</div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import html2canvas from 'html2canvas';
import $ from 'jquery'
import Tscore from '/src/components/Tscore'

export default {
    name: "AssessDetail",
    data() {
        return {
            printObj: {
                id: "print",//要打印的id名 无#号
                popTitle:'&nbsp;',//页眉标题 默认浏览器标题 空字符串时显示undefined 使用html语言
                extraHead:'&nbsp;',//头部文字 默认空
            },
            xiugai:[],
            xianshi1:false,
            xianshi2:false,
            dialogChuan:false,
            // impressionList:{},
            // jcjg_infoCaozuo:[],
            // clinicalList:[],
            // maladjustmentList:[],
            // credit_validityList:[],
            // contentList:[],
            // predictabilityList:[],
            // sexual_functionList:[],
            // psychologyList:[],
            // char_infoList:[],
            // personality_analysisList:[],
            // auxiliaryList:[],
            // stateFlag: false,
            // tscoreMap: {},
            // timeStamp: '',
            score: [],
            // table2Seq: ['D', 'Hs', 'Pt', 'Pd', 'Si', 'Pa', 'Sc', 'Mf', 'Ma', 'F', 'K', 'L'],
            // tableOne: {},
            // mmpt: {
            //     props: ['origin_score', 'k_score', 'cn_score', 'cn_k_score', 'usa_k_score'],
            //     baseTitle: [
            //         {t1: '疑问 分'},
            //         {t1: '说谎 分'},
            //         {t1: '诈病 分'},
            //         {t1: '校正 分'},
            //         {t1: '疑病 症'},
            //         {t1: '抑郁 症'},
            //         {t1: '癔 症'},
            //         {t1: '精神病态'},
            //         {t1: '男女子气'},
            //         {t1: '偏执 狂'},
            //         {t1: '精神衰弱'},
            //         {t1: '精神分裂'},
            //         {t1: '轻躁狂症'},
            //         {t1: '社会内向'},
            //     ],
            //     tableTwo: {}
            // },
            isFinished: false,
            info: [],
            detailForm: [],
            printForm: [],
            // district: [],
            printer: [],
            printData: [
                {id: '1', name: '是否打印图片', value: false},
                {id: '2', name: '患者详细数据打印', value: false},
                {id: '3', name: '检测结果打印', value: false},
                // {id: '4', name: '结论打印', value: false},
                {id: '5', name: '行为观察打印', value: false},
                {id: '6', name: '医生建议', value: false},
            ],
            infoData: [],
            dialogPrint: false,
            dialogIpt: false,
            dialogText: '',
            // doctorAdvised: '',
            current: {},
            currentMethod: '',
            dialogProposal: false,
            // dialogProposal_:false,
            // options: [],
            // week_id:'',
            doctorProposalList: [],
            doctorProposal: '',
            dialogReport: false,
            wardList: [],
            printPageSize: '',
            displayBlock: '2',
            dialogPreview: false,
            previewImg: '',
            print_page: 1,
            modifyTitle: {title: '医生建议', type: 5, field: 'doctor_proposal'},
            templateText: '',
            showEdit: false,
            Leisure: false,
            // LeisureList: [],
            // calcName: '',
            printShow: true,
            printASKQX: false,
            // physiologyList: [],
            doctor_proposal:'',
            suggestion:'',
            conclusion:'',
            textArea:'',
            height:'',
            scrollHeight:'',
            zhi:'',
            // two_point:'',
            educationInformationArr: [],
            careerInformationArr: [],
            checkArr:[],
        }
    },
    components: {
        't-score': Tscore
    },
    computed: {
        // comDanger: function () {
        //     let arr = []
        //     if (this.infoData.detail) {
        //         const danger = this.infoData.detail.wisc_info.child.yuyan
        //         let first = {
        //             title: '量表分',
        //             first: true
        //         }
        //         let last = {
        //             title: 'sss',
        //             last: true
        //         }
        //         arr = [first, ...danger, last]
        //
        //     }
        //     return arr
        // },
        // comDanger01: function () {
        //     let arr = []
        //     if (this.infoData.detail) {
        //         const danger = this.infoData.detail.wisc_info.child.caozuo
        //         let first = {
        //             title: '量表分',
        //             first: true
        //         }
        //         let last = {
        //             title: 'sss',
        //             last: true
        //         }
        //         arr = [first, ...danger, last]
        //         console.log('arr',arr)
        //     }
        //     return arr
        // },

    },
    watch:{
        'doctor_proposal':function(nv,ov) {
            if (nv === ov) {
                return
            }
            this.doctor_proposal = nv
            this.changeHeight()
        },
        'suggestion':  function (nv,ov) {
            if (nv === ov) {
                return
            }
            this.suggestion = nv
            this.changeHeight__()
        },
        'conclusion':function (nv,ov) {
            if (nv === ov) {
                return
            }
            this.conclusion = nv
            this.changeHeight_()
        },
    },
    async mounted() {
        this.$vLoading.show();
        console.log('this.$route.query',this.$route.query)
        this.detailForm.uid = this.$store.state.user.userInfo.uid
        this.detailForm.exam_id = this.$route.query.id
        this.detailForm.patient_id = this.$route.query.patient_id
        await this.getInfo();
        await this.getPrinter();
        this.isFinished = true;
        await this.getWard();
        this.$vLoading.hide();
    },

    methods: {
        /**
         * echarts
         * */
        drawLineA() {
            let myChartA = this.$echarts.init(document.getElementById('myChartA'))
            console.log('this.infoData.detail.wisc_info.child.yuyan',this.infoData.detail.wisc_info.child.yuyan)
            let data = []
            let titleData = []
            this.infoData.detail.wisc_info.child.yuyan.map((item,index) => {
                data.push(item.score)
                titleData.push(item.title)
            })
            // 绘制图表
            myChartA.setOption({
                title:{
                    subtext: "言语测验",
                    left: "center",
                    textStyle: {
                        fontSize: 24
                    },
                    subtextStyle: {
                        fontSize: 22,
                        color: 'rgba(27, 25, 25, 1)',
                        fontWeight: "400",
                    },
                    show: true
                },
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    top: 80,
                    show: true,
                    left: "1%",
                    containLabel: true,
                    lineStyle: {
                        color: '#979797',
                    }
                },
                xAxis: {
                    type: 'value',
                    name:'量表分',
                    nameTextStyle: {
                        fontSize: 14 // 修改字体大小
                    },
                    nameLocation: 'start',     //有start  和end 分别为左右
                    position:'top',
                    axisLine:{
                        // show: true, // 是否显示
                        lineStyle: {
                            color: '#979797',
                            width: '1'
                        }
                    },
                    axisLabel: {
                        fontSize: 14 // 设置 Y 轴文字大小为 14
                    },
                    // data:['量表分',0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,''],
                    min:0,
                    max:19,
                    interval: 1,
                    splitLine: {
                        show: false
                    },
                    axisTick: { show: true },
                    // axisLabel:{
                    //     formatter:function (params) {
                    //         console.log(params)
                    //         let a = ''
                    //         if(params == -1) {
                    //             a = '量表分'
                    //         } else {
                    //             a = params
                    //         }
                    //         return a
                    //     },
                    // }
                },
                yAxis: {
                    type: 'category',
                    data: titleData.reverse(),
                    interval: "auto",
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#979797'
                        },
                    },
                    axisLabel: {
                        fontSize: 14 // 设置 Y 轴文字大小为 14
                    },

                    //x轴坐标刻度
                    axisTick: { show: false },
                },
                series: [
                    {
                        data: data.reverse(),
                        type: 'line',
                        // smooth: true,
                        symbol:'circle',
                        symbolSize:10,
                        lineStyle: {
                            color:'#62B5FB', //折线的颜色
                        },
                        itemStyle: {
                            normal: {
                                color:'#62B5FB', //折线点的颜色
                                borderColor: '#D8EDFF', //拐点边框颜色
                                borderWidth: 3, //拐点边框大小
                            },
                        },
                        markLine: {
                            symbol: 'none',
                            label: {
                                show: false
                            },
                            lineStyle: {
                                color: '#979797'
                            },
                            data: [
                                {
                                    xAxis: 10,
                                    lineStyle: {
                                        type: 'dashed'
                                    },
                                },

                            ]
                        }
                    }
                ]
            });
        },
        /**
         * echarts
         * */
        drawLineB() {
            let myChartB = this.$echarts.init(document.getElementById('myChartB'))
            let data = []
            let titleData = []
            this.infoData.detail.wisc_info.child.caozuo.map((item,index) => {
                data.push(item.score)
                titleData.push(item.title)
            })
            // 绘制图表
            myChartB.setOption({
                title:{
                    subtext: "操作测验",
                    left: "center",
                    textStyle: {
                        fontSize: 24
                    },
                    subtextStyle: {
                        fontSize: 22,
                        color: 'rgba(27, 25, 25, 1)',
                        fontWeight: "400",
                    },
                    show: true
                },
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    top: 80,
                    show: true,
                    left: "1%",
                    containLabel: true,
                    lineStyle: {
                        color: '#979797',
                    }
                },
                xAxis: {
                    name:'量表分',
                    nameTextStyle: {
                        fontSize: 14 // 修改字体大小
                    },
                    nameLocation: 'start',     //有start  和end 分别为左右
                    position:'top',
                    axisLine:{
                        show: true, // 是否显示
                        lineStyle: {
                            color: '#979797',
                            width: '1'
                        }
                    },
                    axisLabel: {
                        fontSize: 14 // 设置 Y 轴文字大小为 14
                    },
                    // data:['量表分',0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,''],
                    min:0,
                    max:19,
                    interval: 1,
                    splitLine: {
                        show: false
                    },
                    axisTick: { show: true },
                },
                yAxis: {
                    type: 'category',
                    data: titleData.reverse(),
                    interval: 10,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#979797'
                        },
                    },
                    axisLabel: {
                        fontSize: 14 // 设置 Y 轴文字大小为 14
                    },
                    //x轴坐标刻度
                    axisTick: { show: false },
                },
                series: [
                    {
                        data: data.reverse(),
                        type: 'line',
                        // smooth: true,
                        symbol:'circle',
                        symbolSize:10,
                        lineStyle: {
                            color:'#62B5FB', //折线的颜色
                        },
                        itemStyle: {
                            normal: {
                                color:'#62B5FB', //折线点的颜色
                                borderColor: '#D8EDFF', //拐点边框颜色
                                borderWidth: 3, //拐点边框大小
                            },
                        },
                        markLine: {
                            symbol: 'none',
                            label: {
                                show: false
                            },
                            lineStyle: {
                                color: '#979797'
                            },
                            data: [
                                {
                                    xAxis: 10,
                                    lineStyle: {
                                        type: 'dashed'
                                    },
                                },

                            ]
                        }
                    }
                ]
            });
        },
        /**
         * 修改报告点击
         * */
        xiu() {
            this.dialogReport = true
            this.xiugai = JSON.parse(JSON.stringify(this.infoData.patient_info));
        },
        /**
         * 修改报告确定按钮
         * */
        xiuqueding() {
            this.infoData.patient_info = JSON.parse(JSON.stringify(this.xiugai))
            this.dialogReport = false

        },
        /**
         * 修改报告取消按钮
         * */
        xiuquxiao() {
            this.dialogReport = false
        },
        // comCell2(info) {
        //     //let head = {background: '#F6F6F6'}
        //     //console.log('table', info)
        //     if (info.rowIndex === 0 && info.columnIndex === 3) {
        //         return 'table-two-cell-slot'
        //     }
        //     if (info.rowIndex === 0) {
        //         return 'table-two-cell-one'
        //     }
        // },
        comCell3(info){
            if (info.rowIndex === 0 && info.columnIndex === 3) {
                return 'table-three-cell-slot'
            }
            if (info.rowIndex === 0) {
                return 'table-two-cell-one'
            }
        },
        // comCell4(info){
        //     if (info.rowIndex === 0 && info.columnIndex === 2) {
        //         return 'table-three-cell-slot'
        //     }
        //     if (info.rowIndex === 0) {
        //         return 'table-two-cell-one'
        //     }
        // },
        comScoreClass(info) {
            if (info.columnIndex === 3) {
                return 'tscore-col'
            }
        },
        // async delete_(id,index){
        //     await this.$axios.post('api/template/delete',{id:id}).then(res => {
        //         // console.log(res.data.info)
        //     });
        //     this.doctorProposalList.splice(index, 1)
        //     this.templateText=''
        //     this.doctorProposal=''
        //     console.log(id)
        //     console.log(index)
        //     console.log(this.doctorProposalList)
        // },
        /**
         * 获取打印设置
         */
        async getPrinter() {
            await this.$axios.post('api/setting/printer_set_info', {
                type: 1
            }).then(res => {
                if (res.data.code === 1) {
                    this.printForm = res.data.data.item.map(item => item);
                    this.printPageSize = res.data.data.paper;
                    this.displayBlock = res.data.data.edit
                }
                this.printData.map(item => {
                    item.value = this.printForm.indexOf(item.id) !== -1;
                    if(item.id == 1 && item.value == true ) {
                        this.$nextTick(() => {
                            setTimeout(() => {
                                this.drawLineA();
                                this.drawLineB()
                            },500)
                        })
                    }
                })
                this.$forceUpdate();
            });
            if (this.displayBlock == 1) {
                this.showEdit = true
            } else if (this.displayBlock == 2) {
                this.showEdit = false
            }
        },

        /**
         * 获取报告数据
         */
        async getInfo() {
            await this.$axios.post('api/Report/report', this.$qs.stringify(this.detailForm)).then(res => {
                if (res.data.code === 1) {
                    this.xianshi1 = true
                    this.xianshi2 = false
                    this.infoData  = res.data.data;
                    // console.log('this.infoData',this.infoData)
                    this.conclusion = this.infoData.conclusion
                    this.suggestion = this.infoData.suggestion
                    this.doctor_proposal = this.infoData.doctor_proposal
                    // this.LeisureList = res.data.data.answer_detail
                    // this.calcName = res.data.data.calc_name
                    // this.physiologyList = res.data.data.physiology
                    // if(res.data.data.calc_name === 'MMPICT'){
                    //     this.physiologyList = res.data.data.physiology
                    //     // this.impressionList = res.data.data.detail.impression
                    //     // this.jcjg_infoCaozuo = res.data.data.detail.jcjg_info.child.
                    //     // this.clinicalList = res.data.data.detail.clinical
                    //     // this.maladjustmentList = res.data.data.detail.maladjustment
                    //     // this.credit_validityList = res.data.data.detail.credit_validity
                    //     // this.contentList = res.data.data.detail.content
                    //     // this.predictabilityList = res.data.data.detail.predictability
                    //     // this.sexual_functionList = res.data.data.detail.sexual_function
                    //     // this.psychologyList = res.data.data.detail.psychology
                    //     // this.char_infoList = res.data.data.detail.char_info
                    //     // this.personality_analysisList = res.data.data.detail.personality_analysis
                    //     // this.auxiliaryList = res.data.data.detail.auxiliary
                    //     // this.two_point = res.data.data.detail.two_point
                    // }

                    // this.initMmpt(this.infoData)
                }
                if (res.data.code === 400) {
                    this.xianshi1 = false
                    this.xianshi2 = true
                    this.zhi = res.data.info
                    this.dialogChuan = true;
                }
                try {
                    this.Leisure = this.LeisureList.length !== 0;
                } catch (e) {
                }
            });
            // this.stateFlag = true
        },
        handleClose() {
            this.$router.go(-1)
        },

        /* initTscore() {
             let that = this
             var refs = this.$refs
             this.$nextTick(() => {
                 console.log('refs', this.$refs)
                 for (let key in this.$refs) {
                     if (key.startsWith('tscore')) {
                         this.$refs[key].clientWidth = '100px'
                         this.$refs[key].clientHeight = '100px'

                         this.tscoreMap[key] = this.$echarts.init(this.$refs[key])
                         let option = {
                             grid:{
                                 width:100,
                                 height:100
                             },
                             xAxis: {
                                 type: 'category',
                                 data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                             },
                             yAxis: {
                                 type: 'value'
                             },
                             series: [{
                                 data: [150, 230, 224, 218, 135, 147, 260],
                                 type: 'line'
                             }]
                         };
                         this.tscoreMap[key].setOption(option)
                     }
                 }
             })
             //that.tscoreArr.push(that.$echarts.init(that.$refs[`tscore${$index}`]))
         },*/

        // initMmpt(info) {
        //     try {
        //         console.log('init mmpt',info)
        //         let list = info.detail.base.list
        //         this.mmpt.baseList = list
        //         // this.timeStamp = new Date().getMilliseconds()
        //     }catch (e){
        //
        //     }
        // },


        /**
         * 设置打印参数
         */
        async printSet() {
            this.printData.map(item => {
                item.value = this.printForm.indexOf(item.id) !== -1;
            })
            await this.$axios.post('api/setting/printer_set', {
                item: this.printForm,
                paper: this.printPageSize,
                edit: this.displayBlock,
                type: 1
            }).then(res => {
                this.dialogPrint = false
                this.getPrinter()
            })
        },


        /**
         * 显示编辑
         */
        changeRadio(e) {
            console.log("~ e", e)
            // if(e == 1){
            //     this.showEdit = true
            //     console.log("~ this.showEdit", this.showEdit)
            // }else if(e == 2){
            //     this.showEdit = false
            //     console.log("~ this.showEdit", this.showEdit)
            // }
        },

        textConfirm() {
            console.log('this.current',this.current);
            if (this.current.desc) {
                this.current.desc = this.dialogText;
            } else if (this.currentMethod === 'conclusion') {
                this.current.conclusion = this.dialogText;
            } else if (this.currentMethod === 'suggestion') {
                this.current.suggestion = this.dialogText;
            }
            this.dialogIpt = false;
            this.dialogText = '';
        },

        /**
         * 修改医生建议  行为观察   结论  点击
         * */
        async getProposal({title, type, field}) {
            console.log(type)
            this.modifyTitle = {title, type, field}
            this.doctorProposal = this.infoData[field];
            this.templateText = this.infoData[field];
            await this.$axios.post('api/template/lst', {
                // test_paper_id: this.$route.query.id,
                type: type
            },).then(res => {
                this.doctorProposalList = res.data.data;
            });
            this.dialogProposal = true;

        },
        // onWeekInfo(e){
        //     this.week_id = e
        // },
        // async proposalConfirm_(){
        //     this.dialogProposal_ = false
        //     this.$axios.post('api/common/week_info', {id : this.week_id}).then(res => {
        //         if (res.data.code === 1) {
        //             this.yunzhouzhidao = res.data.data.content
        //         }
        //     })
        //     console.log(this.yunzhouzhidao)
        // },

        /**
         * 历史模板确定按钮
         * */
        async proposalConfirm() {
            if (!this.templateText) {
                return;
            }
            let list = this.doctorProposalList.map(item => item.content);
            if (list.indexOf(this.templateText) === -1) {
                await this.$axios.post('api/template/add', {
                    // test_paper_id: this.$route.query.id,
                    content: this.templateText,
                    type: this.modifyTitle.type
                })
            }
            this.dialogProposal = false;
            this.infoData[this.modifyTitle.field] = this.templateText;
            await this.$axios.post('api/template/add', {
                // test_paper_id: this.$route.query.id,
                patient_id:this.$route.query.patient_id,
                content: this.templateText,
                type: this.modifyTitle.type,
                exam_id: this.$route.query.id
            })
            if (this.modifyTitle.type == 5) {
                this.doctor_proposal = this.infoData[this.modifyTitle.field]
            }
            if (this.modifyTitle.type == 2) {
                this.suggestion = this.infoData[this.modifyTitle.field]
            }
            if (this.modifyTitle.type == 6) {
                this.conclusion = this.infoData[this.modifyTitle.field]
            }
            this.templateText = '';
        },
        changeHeight () {
            let _this = this
            this.$nextTick(() => {
                if (_this.modifyTitle.type == 4) {
                    var textArea = _this.$refs.test_two
                }
                if (_this.modifyTitle.type == 5) {
                    var textArea = _this.$refs.test_one
                }
                let scrollHeight = textArea.scrollHeight // 控件所有的高度，包含滚动的那部分(不可见也会有高度)
                let height = textArea.offsetHeight // 屏幕上显示的高度
                if (height <= scrollHeight) {
                    textArea.style.height = 'auto' // 恢复默认值，这个作用就是根据内容自适应textarea高度
                    textArea.style.height = textArea.scrollHeight + 'px' // 拿到最新的高度改变textarea的高度
                }else {
                    textArea.style.height = 'auto' // 恢复默认值，这个作用就是根据内容自适应textarea高度
                }
            })
        },
        changeHeight_ () {
            let _this = this
            this.$nextTick(() => {
                setTimeout(() => {
                    _this.textArea = _this.$refs.test_five
                    let scrollHeight = _this.textArea.scrollHeight // 控件所有的高度，包含滚动的那部分(不可见也会有高度)
                    let height = _this.textArea.offsetHeight // 屏幕上显示的高度
                    if (height <= scrollHeight) {
                        _this.textArea.style.height = 'auto' // 恢复默认值，这个作用就是根据内容自适应textarea高度
                        _this.textArea.style.height = _this.textArea.scrollHeight + 'px' // 拿到最新的高度改变textarea的高度
                    }else {
                        _this.textArea.style.height = 'auto' // 恢复默认值，这个作用就是根据内容自适应textarea高度
                    }
                },1000)

            })
        },
        changeHeight__ () {
            let _this = this
            this.$nextTick(() => {
                setTimeout(() => {
                    _this.textArea = _this.$refs.test_four
                    let scrollHeight = _this.textArea.scrollHeight // 控件所有的高度，包含滚动的那部分(不可见也会有高度)
                    let height = _this.textArea.offsetHeight // 屏幕上显示的高度
                    if (height <= scrollHeight) {
                        _this.textArea.style.height = 'auto' // 恢复默认值，这个作用就是根据内容自适应textarea高度
                        _this.textArea.style.height = _this.textArea.scrollHeight + 'px' // 拿到最新的高度改变textarea的高度
                    }else {
                        _this.textArea.style.height = 'auto' // 恢复默认值，这个作用就是根据内容自适应textarea高度
                    }
                },1000)

            })
        },
        changeHeight___ () {
            let _this = this
            this.$nextTick(() => {
                setTimeout(() => {
                    _this.textArea = _this.$refs.test_four
                    let scrollHeight = _this.textArea.scrollHeight // 控件所有的高度，包含滚动的那部分(不可见也会有高度)
                    let height = _this.textArea.offsetHeight // 屏幕上显示的高度
                    if (height <= scrollHeight) {
                        _this.textArea.style.height = 'auto' // 恢复默认值，这个作用就是根据内容自适应textarea高度
                        _this.textArea.style.height = _this.textArea.scrollHeight + 'px' // 拿到最新的高度改变textarea的高度
                    }else {
                        _this.textArea.style.height = 'auto' // 恢复默认值，这个作用就是根据内容自适应textarea高度
                    }
                },1000)

            })
        },
        /**
         * 获取接口数据
         * */
        async getWard() {
            //检测人员
            await this.$axios.post('api/patient/check_list').then(res => {
                this.checkArr = res.data.data.list;
            })
            await this.$axios.post('api/district/index').then(res => {
                this.wardList = res.data.data;
            })
            //学历
            await this.$axios.post('api/common/education').then(res => {
                console.log(res)
                this.educationInformationArr = res.data.data;
            })
            //职业
            await this.$axios.post('api/common/occupation').then(res => {
                console.log(res)
                this.careerInformationArr = res.data.data;
            })
        },


        /**
         * 打印预览
         */
        printPreview() {
            let $printNone = $('.print-none').hide();
            let dialog = this.$loading('请稍后...');
            html2canvas(this.$refs.print,{
                allowTaint: true,
                useCORS: true
            }).then(canvas => {
                let dom = document.body.appendChild(canvas);
                dom.style.display = 'none';

                let a = document.createElement('a');
                a.style.display = 'none';
                document.body.removeChild(dom);

                this.previewImg = URL.createObjectURL(this.dataURLToBlob(dom.toDataURL('image/png')));
                this.dialogPreview = true;
                $printNone.hide();
                dialog.close();
                $('.print-none_').show();
            });
        },


        /**
         * data转URLBlob
         * @param data
         * @returns {Blob}
         */
        dataURLToBlob(data) {
            let arr = data.split(',');
            let mime = arr[0].match(/:(.*?);/)[1];
            let bstr = atob(arr[1]);
            let n = bstr.length;
            let u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], {type: mime});
        },


        /**
         * 实现两个元素中心点的连线
         * @author 渀波儿灞 2021-02-20
         * @param  {Object} startObj  jquery对象，起点元素
         * @param  {Object} endObj    jquery对象，终点元素
         * @return {String}           返回连线的dom
         */
        // drawLine(startObj, endObj) {
        //     const yStart = startObj.position().top + startObj.height() / 2;
        //     const xStart = startObj.position().left + startObj.width() / 2;
        //     const yEnd = endObj.position().top + endObj.height() / 2;
        //     const xEnd = endObj.position().left + endObj.width() / 2;
        //
        //     //用勾股定律计算出斜边长度及其夹角（即连线的旋转角度）
        //     const lx = xEnd - xStart;
        //     const ly = yEnd - yStart;
        //
        //     //计算连线长度
        //     const length = Math.sqrt(lx * lx + ly * ly);
        //     //弧度值转换为角度值
        //     const c = 360 * Math.atan2(ly, lx) / (2 * Math.PI);
        //
        //     //连线中心坐标
        //     const midX = (xEnd + xStart) / 2;
        //     const midY = (yEnd + yStart) / 2;
        //     const deg = c <= -90 ? (360 + c) : c;  //负角转换为正角
        //
        //     return `<div class='charts-16pf-line' style='top:${midY}px;left:${midX - length / 2}px;width:${length}px;transform:rotate(${deg}deg);'></div>`
        // }
    },

}
</script>
<style media="print">
@page{
    size :auto;
    margin: 25px;
}
</style>
<style scoped lang="scss">
.table-name-box-bottom {
    display: flex;
    font-size: 18px;
    font-weight: bold;
    //border: 1px solid  #E6E6E6;
    background: #F6F6F6;
    border-bottom: none;
    color: #333333;
    >div {
        padding: 15px 0;
        text-align: center;
    }
}
.table-name-box {
    display: flex;
    font-size: 20px;
    font-weight: bold;
    border: 1px solid  #EBEEF5;
    border-bottom: none;
    >div {
        padding: 15px 0;
        width: 50%;
        text-align: center;
    }
}
::v-deep{
    .el-radio-group {
        margin-left: 20px;
    }
}
::v-deep {
    .el-form-item__content {
        width: 250px;
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #62B5FB;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner {
        border-color: #62B5FB;
        background-color: #62B5FB;
    }
    .el-checkbox__inner:hover {
        border-color: #62B5FB;
    }
    .el-radio__input.is-checked + .el-radio__label {
        color: #62B5FB;
    }
    .el-radio__input.is-checked .el-radio__inner {
        border-color: #62B5FB;
        background-color: #62B5FB;
    }
    .el-button--primary {
        border-color: #62B5FB;
        background-color: #62B5FB;
    }
    .el-button [class*=el-icon-] + span {

    }
    .el-table .cell {
        font-size: 16px;
    }
    .el-table th > .cell {
        font-size: 18px;
    }
}
.check_jiance {
    font-size: 22px !important;
    margin-bottom:20px;
    ::v-deep .el-checkbox__label {
        font-size: 22px !important;
        color: #333333!important;
        font-weight: 600;
    }
}
.pull_left {
    display: inline-block;
    width: 95%;
    overflow: hidden;
}

::v-deep .new-dialog span,
::v-deep .new-dialog label {
    font-size: 20px;
}

::v-deep .el-dialog {
    //width: 35%;
}

.dialog-footer {
    .el-button {
        border-radius: 25px;
        width: 150px;
    }
}

.el-form-item {
    margin-bottom: 10px;
}

.el-form-item:last-child {
    margin-bottom: 0;
}

::v-deep .el-checkbox {
    padding-left: 20px;
    width: 98%;
}

.content_body {
    padding: 25px;
    overflow-y: auto;
    @media print {
        overflow: visible;
        height: unset;
    }
}

.remark {
    color: #999999;
    font-size: 16px;
    margin: 0;
    text-align: center;

}


.print-html {
    max-width: 1100px;
    margin: 0 auto;

    .print-none {
        display: none;
    }

    .edit-btn.active {
        display: block;
    }

    @media print {
        table td,
        table th {
            border: 1px #000000 solid;
        }
    }
}

.print-html > .title {
    color: #333333;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.print-html > .title img {
    width: auto;
    height: 75px;
    // border-radius: 50%;
    margin-right: 15px;
}

.print-html > .title p {
    font-size: 38px;
    margin-bottom: 5px;
}

.table-name {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 0 auto 15px;
    font-size: 18px;
    color: #333333;
}

.table-name p {
    font-size: 26px;
    font-weight: bold;
}

.print-html .edit-btn {
    color: #62B5FB;
    position: absolute;
    right: 0;
    cursor: pointer;
    @media print {
        display: none !important;
    }
}

.print-body {
    border: 1px solid #e6e6e6;
    border-bottom: none;
}

.print-body .first {
    display: flex;
}

.print-body .first > .first-group {
    flex: 1;
    padding: 10px 25px;
    border-right: 1px solid #e6e6e6;
    @media print {
        padding: 10px 0;
    }
}

.print-body .row {

    display: flex;
    align-items: center;
    font-size: 20px;
    color: #333333;
    padding-left: 25px;
    margin-bottom: 5px;
}

.print-body .first > .first-group span {
    width: 80px;
    text-align-last: justify;
}

.print-body .first > .first-group p {
    margin-left: 15px;
    font-weight: bold;
}

.print-last-row,
.first,
.print-row {
    border-bottom: 1px solid #e6e6e6;
}

.print-row {
    padding: 20px;
    color: #333333;
    font-size: 28px;
    font-weight: bold;
}

.print-row .title {
    text-align: center;
    line-height: 50px;
    margin-bottom: 20px;
}

.tip {
    margin-top: 36px;
    text-align: center;
    height: 22px;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
}

.print-row .table-name p {
    height: 30px;
    font-size: 28px;
    margin-bottom: 20px;
}

.print-row .table-name span {
    font-size: 14px;
}

.print-last-row {

    padding: 10px 55px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.print-last-row .group {
    flex: 1;
    display: flex;
    //line-height: 55px;
    font-size: 20px;
    align-items: center;
    height: 50px;
}

.group > p {
    margin-left: 15px;
    font-weight: bold;
}
.jiance {
    font-size: 22px;
    >p {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        >span {
            display: block;
        }
        span:nth-child(1) {
            width: 40%;
            padding-right: 10px;
        }

        span:nth-child(2) {
            //width: 2%;
        }

        span:nth-child(3) {
            width: 40%;
        }
    }
}
.check-result {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    > span {
        display: block;
        font-size: 22px;
    }

    span:nth-child(1) {
        width: 40%;
    }

    span:nth-child(2) {
        width: 20%;
    }

    span:nth-child(3) {
        width: 40%;
    }
}

.text-dialog ::v-deep .el-dialog__header {
    padding: 0;
}

.text-content {
    border: 1px solid #e6e6e6;

    > textarea {
        width: 100%;
        height: 100%;
        padding: 8px;
        box-sizing: border-box;
        font-size: 18px;
    }
}

.button-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.proposal-text {
    display: flex;
    align-items: center;
    margin-top: 30px;
    position: relative;

    > span {
        width: 80px;
        text-align-last: justify;
        margin-right: 20px;
    }

    > textarea {
        width: 80%;
        font-size: 18px;
        padding: 8px;
        border: 1px solid #cdcdcd;
        box-sizing: border-box;
    }

    ::v-deep .el-select-dropdown {
        max-width: 30em;
        top: unset !important;
        left: unset !important;
    }
}
.physical-signs_item{
    font-size: 16px;
    color: #333;
    margin: 10px 0;
    font-weight: 400;
}

.physical-signs {
    display: flex;
    padding: 15px 0;
    border-bottom: 1px solid #e6e6e6;

    > div {
        font-size: 22px;
        display: flex;
        > span {
            display: block;
            color: #333333;
            margin-right:2em;
        }
    }

    > div:first-child {
        color: #62B5FB;
    }
}

.physical-signs:last-child {
    border: none;
}

.page-A4 {
    img {
        width: 100%;
        height: auto;
    }
}

.scl90-analysis {
    > * {
        font-size: 18px;
        margin-bottom: 1em;
    }
}

.report-text-content {
    width: 100%;
    display: block;
    flex: 1;
    font-size: 22px;
    font-family: -apple-system-font, "Helvetica Neue", sans-serif, "Microsoft YaHei";
    line-height: 34px;
}
.charts-16pf {
    position: relative;

    table {
        border-collapse: collapse;
        width: 100%;

        th, td {
            border: 1px #eeeeee solid;
            padding: 8px;
            font-size: 14px;
            font-weight: normal;
        }

        //thead {
        //    background: #e5e5e5;
        //}

        .flex {
            height: 40px;
        }

        .flex-grow1 {
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;

            i {
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 4px;
                position: relative;
                z-index: 2;
            }

            &.active {
                i {
                    background: #62B5FB;
                    box-shadow: 0 0 5px #62B5FB;
                }
            }
        }

        .line {
            &:before {
                content: " ";
                display: block;
                width: 0;
                border-right: 1px #e5e5e5 solid;
                height: 100%;
                margin-right: -2px;
                position: relative;
                z-index: 1;
            }
        }

        tfoot {
            td {
                border: 0;

                .flex-grow1 {
                    flex-direction: column;
                    justify-content: flex-start;

                    i {
                        width: 0;
                        height: 10px;
                        border-right: 1px #e5e5e5 solid;
                    }

                    span {
                        color: #999;
                    }
                }
            }
        }
    }
}


.MMPICT {

    .table-count {
        display: flex;
        padding-left: .5em;
        justify-content: space-between;
        padding-right: 140px;
        margin-top: 15px;
        margin-bottom: 20px;

        & > * {
            color: #333333;
            font-size: 16px;
        }
    }

    //.scale {
    //
    //    .thead {
    //        display: flex;
    //
    //        .headl {
    //            margin-left: 46px;
    //            margin-right: 160px;
    //            width: 4em;
    //            text-align: justify;
    //            text-align-last: justify;
    //            background: #09DBDA;
    //        }
    //
    //        .headr {
    //            flex: 1;
    //            background: #b293fb;
    //        }
    //    }
    //
    //}

    .MMPICT-ii {
        width: 100%;
        .score {
            display: flex;
            justify-content: center;
            margin-top: 23px;

            .item {
                margin-right: 24px;
                margin-bottom: 5px;
                font-size: 12px;
                font-weight: 500;
                color: #999999;
                line-height: 17px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                &:last-child {
                    margin-right: 0;
                }

            }
        }

        .table-two {
            width: 100%;
            //margin-top: 30px;
        }
        .table-one {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .titleTop {
                display:grid;
                grid-template-columns:repeat(11,96px);
                font-size: 18px;
                text-overflow: ellipsis;
                white-space: normal;
                word-break: break-all;
                line-height: 23px;
                text-align: center;
                padding: 12px 0;
                background: #F6F6F6;
                color: #333333;
                border: 1px solid #EBEEF5;
            }
            .titleTop1 {
                font-size: 16px;
                background: #ffffff;
                border-top: none;
                color: #606266;
            }
        }
    }
}

</style>
<style>
.report-text-content p,
.report-text-content div {
    margin-bottom: 0.8em;
}

.scl90-table-header th {
    background: #e5e5e5;
    color: #000000;
}


.charts-16pf-line {
    position: absolute;
    background-image: linear-gradient(to right, #7A40F2, #b293fb);
    height: 1px;
    z-index: 1;
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
    }
}


</style>

<style lang="scss">
.tscore-col {
    * {
        overflow: visible !important;

        div {
            width: 100% !important;
            //padding-right: 10px !important;
        }
    }
}

/*.table-two-header-row{
    border: none;
}*/

.tscore {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;

    * {
        overflow: visible !important;
        width: 100%;
    }
}

.head-row {
    height: 120px;

    .cell {
        height: 100% !important;
        padding-top: 10px
    }

    *[type="ho"] {
        height: 100%;
        display: flex;
        align-items: flex-end;
    }

    *[type="ver"] {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        line-height: 20px;

        .tone {
            color: #333333;
            font-size: 18px;
            height: 4em !important;
            overflow: hidden;

            writing-mode: vertical-lr;
            display: flex;
            align-items: center;
            justify-content: space-between;

            & > div {
                height: fit-content;

                &:nth-child(1) {
                }
            }

        }

        .ttwo {
            margin-top: 5px;
        }
    }
}

.table-left {
    display: flex;
    width: 12em;
    justify-content: space-between;

    div {
        float: left;
    }
}

.overline {
    width: 12em;
    text-align-last: justify;
    text-align: justify;
}

.table-two-cell-one {
    background: #F6F6F6 !important;
    border-right: none !important;

    &:last-child {
        border-right: 1px solid #EBEEF5;
    }
}


.table-two-cell-slot {
    @extend .table-two-cell-one;
    /*padding-top: 24px !important;
    padding-bottom: 0 !important;*/
    display: flex;
    align-items: flex-end;
    overflow: visible !important;


    .cell {
        margin-top: 12px;
        margin-bottom: -12px;
        display: flex !important;
        padding: 0 20px 0 0 !important;
        justify-content: space-between;
        overflow: visible !important;


        .table2-head {
            margin-left: -5px;
            display: flex;
            height: fit-content;
            justify-content: space-between;
        }
    }
}
.table-three-cell-slot{
    @extend .table-two-cell-one;
    /*padding-top: 24px !important;
    padding-bottom: 0 !important;*/
    display: flex;
    align-items: flex-end;
    overflow: visible !important;
    text-align: center !important;


    .cell {


        .table2-head {
            margin-left: -5px;
            display: flex;
            height: fit-content;
            justify-content: space-between;
        }
    }
}


</style>
